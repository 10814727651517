import { BLANK_SLATE_DOCUMENT } from "../core/constants";
import isEqual from "lodash/isEqual";

export const convertToDateInputFormat = (dateString) => {
  // Return early if string is not valued, or if date string is already date input format
  if (!dateString) {
    return "";
  } else if (dateString.indexOf("-") !== -1) {
    return dateString;
  }

  // Turns something like 05/31/2026 into 2026-05-31
  const [month, day, year] = dateString.split("/");

  return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
};

export const convertFromDateInputFormat = (dateString) => {
  // Return early if string is not valued, or if date string is already event date format
  if (!dateString) {
    return null;
  } else if (dateString.indexOf("/") !== -1) {
    return dateString;
  }

  // Turns something like 2026-05-31 into 05/31/2026
  const [year, month, day] = dateString.split("-");

  return `${month.padStart(2, "0")}/${day.padStart(2, "0")}/${year}`;
};

export const getPageHasGeneralContent = (page = {}) => {
  const hasGeneralContent =
    page.title ||
    page.image ||
    (page.content && !isEqual(page.content, BLANK_SLATE_DOCUMENT));

  return hasGeneralContent;
};
