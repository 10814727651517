import { SITE_TYPES } from "../../core/constants";
import WebsiteCustomPage from "../../custom-pages/website/WebsiteCustomPage";
import Events from "../../events/website/Events";
import Page from "../../pages/website/Page";
import PageTitle from "../../pages/website/PageTitle";
import Photos from "../../photos/website/Photos";
import RefSource from "../../ref-monitor/RefSource";
import CashRegistry from "../../registries/dashboard/CashRegistry";
import Registry from "../../registries/website/Registry";
import RSVP from "../../rsvp/website/RSVP";
import Travel from "../../travel/website/Travel";
import WeddingParty from "../../wedding-party/website/WeddingParty";
import { getCashRegistrySlug } from "../utils";
import EmptyPage from "./EmptyPage";
import { CommentSection } from "./holiday-website/CommentSection";
import { css } from "@emotion/react";
import { styleUtils, theme } from "@minted/minted-components";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React from "react";

const websitePagesStyles = {
  wrapper: (isWedding) => css`
    & > div {
      margin-bottom: ${isWedding ? styleUtils.rem(80) : styleUtils.rem(40)};
    }
    & > div:last-child {
      margin-bottom: ${isWedding
        ? styleUtils.rem(50)
        : styleUtils.rem(10)} !important;
    }
  `,
};

const dashboardUrl = (siteId) => `/site/${siteId}`;

const renderEvents = (
  { key, ref, siteId, siteType, ...props },
  events,
  source,
) => {
  const isOnlineInvitationSite = siteType === SITE_TYPES.ONLINE_INVITATION;

  if (isEmpty(events)) {
    return (
      <div key={key} ref={ref}>
        <EmptyPage
          {...props}
          headline="You haven't created any public events yet."
          removeSpacing
          source={source}
        >
          Once you've created a public event in the{" "}
          <a href={`${dashboardUrl(siteId)}/pages/events`} target="_parent">
            {isOnlineInvitationSite
              ? "Website Manager"
              : "Wedding Website Manager"}
          </a>
          , it will show up here with details, directions, and maps.
        </EmptyPage>
      </div>
    );
  }

  return (
    <div key={key} ref={ref}>
      <div>
        <Events {...props} events={events} removeSpacing />
      </div>
    </div>
  );
};

const renderWeddingParty = ({ key, ref, ...props }, attendants, source) => {
  if (isEmpty(attendants)) {
    return (
      <div key={key} ref={ref}>
        <EmptyPage
          {...props}
          headline="You haven't added your wedding party yet."
          removeSpacing
          source={source}
        >
          Once you add a person in your wedding party, they will show up here.
        </EmptyPage>
      </div>
    );
  }

  return (
    <div key={key} ref={ref}>
      <WeddingParty {...props} attendants={attendants} />
    </div>
  );
};

const renderTravel = (
  { key, ref, siteId, ...props },
  accommodations,
  source,
) => {
  if (isEmpty(accommodations)) {
    return (
      <div key={key} ref={ref}>
        <EmptyPage
          {...props}
          headline="You haven't added any travel accommodations yet."
          removeSpacing
          source={source}
        >
          Once you've added accommodations in the{" "}
          <a
            href={`${dashboardUrl(siteId)}/pages/accommodations`}
            target="_parent"
          >
            Wedding Website Manager
          </a>
          , it will show up here with all the details.
        </EmptyPage>
      </div>
    );
  }

  return (
    <div key={key} ref={ref}>
      <Travel {...props} accommodations={accommodations} />
    </div>
  );
};

const renderMedia = ({ key, ref, ...props }, site, page, source) => (
  <div key={key} ref={ref}>
    <Photos
      {...props}
      dashboardUrl={dashboardUrl}
      isMultiPage={site.pageConfiguration === "multi_page"}
      page={page}
      site={site}
      source={source}
    />
  </div>
);

const renderRegistries = (
  { key, ref, siteId, siteType, ...props },
  registries,
  source,
) => {
  if (isEmpty(registries)) {
    const isOnlineInvitationSite = siteType === SITE_TYPES.ONLINE_INVITATION;
    const isWeddingSite = siteType === SITE_TYPES.WEDDING;
    const dashboardRoute = isOnlineInvitationSite ? "sections" : "pages";

    return (
      <div key={key} ref={ref}>
        <EmptyPage
          {...props}
          headline="You haven't added any gift registries yet."
          removeSpacing
          source={source}
        >
          {isWeddingSite && (
            <>
              Once you've added a registry in the{" "}
              <a
                href={`${dashboardUrl(siteId)}/${dashboardRoute}/registry`}
                target="_parent"
              >
                Wedding Website Manager
              </a>
              , it will show up here with all the details.
            </>
          )}
          {isOnlineInvitationSite && (
            <>
              This section will not be visible on your invitation until you{" "}
              <a
                href={`${dashboardUrl(siteId)}/${dashboardRoute}/registry`}
                target="_parent"
              >
                add a registry
              </a>
              .
            </>
          )}
        </EmptyPage>
      </div>
    );
  }

  return (
    <div key={key} ref={ref}>
      <Page id={props.id} pageData={props.pageData} removeSpacing>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            box-sizing: border-box;
            padding-left: ${theme.spacing.x4};
            padding-right: ${theme.spacing.x4};
            gap: ${theme.spacing.x4};
          `}
        >
          <PageTitle>{props.title}</PageTitle>
          {registries.map((registry) => {
            if (registry.cashRegistryId) {
              const cashRegistrySlug = getCashRegistrySlug(registry.url);

              if (cashRegistrySlug) {
                return (
                  <CashRegistry
                    key={registry.id}
                    registryId={registry.cashRegistryId}
                    registrySlug={cashRegistrySlug}
                  />
                );
              }
            } else {
              return (
                <Registry
                  description={registry.description}
                  image={registry.image}
                  key={registry.id}
                  title={registry.title}
                  url={registry.url}
                />
              );
            }
          })}
        </div>
      </Page>
    </div>
  );
};

const renderRsvp = ({ key, ref, ...props }, site, source) => {
  if (!site.hasRsvpEvents) {
    return (
      <div key={key} ref={ref}>
        <EmptyPage
          {...props}
          headline="You haven't created any RSVP-enabled events yet."
          removeSpacing
          source={source}
          title={props.title}
        >
          Once you've created an RSVP-enabled event in the{" "}
          <a href={`${dashboardUrl(site.id)}/pages/events`} target="_parent">
            Wedding Website Manager
          </a>
          , your guests will be able to submit an RSVP through your website.
        </EmptyPage>
      </div>
    );
  }

  return (
    <div key={key} ref={ref}>
      <RSVP {...props} title={props.title} />
    </div>
  );
};

const renderCustom = ({ key, ref, ...props }, page) => (
  <div key={key} ref={ref}>
    <WebsiteCustomPage {...props} page={page.id} />
  </div>
);

const renderGuestBook = ({ key, ref, ...props }, site, comments, isPreview) => (
  <div key={key} ref={ref}>
    <Page
      id={props.id}
      pageData={props.pageData}
      removeSpacing
      title={props.title}
    >
      <CommentSection
        comments={comments}
        isReadOnly={isPreview || site.isDemoSite || site.status === "archived"}
        site={site}
      />
    </Page>
  </div>
);

const WebsitePages = ({
  connectRef,
  isPreview,
  pages,
  resources,
  site,
  source,
}) => {
  const renderedPages = pages.map((page) => {
    const { configuration, givenName, isDefault, slug } = page;
    const baseProps = {
      configuration,
      id: slug,
      isPreview,
      key: slug,
      pageData: page,
      siteId: site.id,
      title: givenName,
    };

    if (isDefault) {
      switch (slug) {
        case "events":
          return connectRef(
            slug,
            "pages",
            renderEvents(baseProps, resources.publicEvents, source),
          );
        case "accommodations":
          return connectRef(
            slug,
            "pages",
            renderTravel(baseProps, resources.accommodations, source),
          );
        case "wedding-party":
          return connectRef(
            slug,
            "pages",
            renderWeddingParty(baseProps, resources.attendants, source),
          );
        case "photos":
          return connectRef(
            slug,
            "pages",
            renderMedia(baseProps, site, page, source),
          );
        case "registry":
          return connectRef(
            slug,
            "pages",
            renderRegistries(baseProps, resources.registries, source),
          );
        case "rsvp":
          return connectRef(slug, "pages", renderRsvp(baseProps, site), source);
        case "guest-book":
          return connectRef(
            slug,
            "pages",
            renderGuestBook(
              baseProps,
              site,
              resources.comments,
              isPreview,
              source,
            ),
          );
      }
    } else {
      return connectRef(slug, "pages", renderCustom(baseProps, page));
    }
  });

  return (
    <div css={websitePagesStyles.wrapper(site.isWedding)}>{renderedPages}</div>
  );
};

WebsitePages.propTypes = {
  isPreview: PropTypes.bool,
  pages: PropTypes.array.isRequired,
  resources: PropTypes.shape({
    accommodations: PropTypes.array.isRequired,
    attendants: PropTypes.array.isRequired,
    guests: PropTypes.array.isRequired,
    photos: PropTypes.array.isRequired,
    publicEvents: PropTypes.array.isRequired,
    registries: PropTypes.array.isRequired,
    rsvpEvents: PropTypes.array.isRequired,
  }).isRequired,
  site: PropTypes.object.isRequired,
};

export default RefSource(WebsitePages);
